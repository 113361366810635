import React from "react"
import { Link } from "gatsby"
import Head from "/components/Holiday2020/components/Head"
import Cta from "/components/Holiday2020/components/Cta"
import { YuleLog, Singalong } from "/components/Holiday2020/components/Video"
import OpenNumber from "/components/Holiday2020/components/OpenNumber"
import MadLib from "/components/Holiday2020/components/MadLib"

import styles from "./styles.module.less"

const pages = [
  "adobe",
  "aramark",
  "converse",
  "dsc",
  "emergingleaders",
  "hp",
  "johnshopkins",
  "jordan",
  "kp",
  "nike-apla",
  "nike-cdsci",
  "nike-na",
  "nikefactorystore",
  "nikeglobalapp",
  "nikeglobalyearinreview",
  "nike",
  "otsn",
  "psd",
  "rohde-and-schwarz",
  "spotify",
  "tacobell",
  "thesis",
]

const KitchenSink = () => {
  return (
    <div className={styles.KitchenSink}>
      <ul></ul>
      {pages.map((p, i) => {
        let a = `/holiday2020/${p}/`
        return (
          <li key={p}>
            <Link to={a}>{p}</Link>
          </li>
        )
      })}
    </div>
  )
}

export default KitchenSink
